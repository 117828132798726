import { render, staticRenderFns } from "./day-markets.table.vue?vue&type=template&id=1dc73d38&scoped=true&"
import script from "./day-markets.table.vue?vue&type=script&lang=ts&"
export * from "./day-markets.table.vue?vue&type=script&lang=ts&"
import style0 from "./day-markets.table.vue?vue&type=style&index=0&id=1dc73d38&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dc73d38",
  null
  
)

export default component.exports