
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import InsightsService, { InsightsServiceS } from '../../insights.service';
import InsightGroupModel from '../../models/insight-group.model';
import type { MarketLeaderInsight } from '../../types';

@Component
export default class DayMarketLeaderTable extends Vue {
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(InsightsServiceS) private insightsService!: InsightsService;

    get groupData() {
        return this.insightsService.dayGroup as InsightGroupModel<MarketLeaderInsight> | null;
    }

    get dayData() {
        if (!this.groupData?.insights) {
            return [];
        }

        const format = (p: string) => `${Math.round(parseFloat(p) * 100)}%`;

        return Object.entries(this.groupData.insights).map(([id, insight]) => ({
            insightId: id,
            fornovaId: insight.fornovaId!,
            name: insight.hotelName || String(insight.fornovaId),
            provider: this.providersService.getProviderLabel(insight.provider!),
            all: format(insight.leaderAllPercent),
            inc: format(insight.leaderIncPercent),
            dec: format(insight.leaderDecPercent),
        }));
    }

    isMyHotel(id: string | number) {
        return this.$route.params.hotelId === String(id);
    }
}
